var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"import"}},[_c('FormulateForm',{staticClass:"import-form",on:{"submit":_vm.importDataFunction},model:{value:(_vm.importForm),callback:function ($$v) {_vm.importForm=$$v},expression:"importForm"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('Title',{staticClass:"col-12",attrs:{"title":_vm.$t('title_import')}}),_c('FormulateInput',{staticClass:"col-12 col-md-6 offset-md-3 py-3 pb-4",attrs:{"label":_vm.$t('form_filetype'),"name":"fileType","validation":"required","validation-name":_vm.$t('form_filetype'),"type":"vue-select","options":[
              { value: 'pcr-results', label: _vm.$t('form_pcr_results') },
              { value: 'pcr-saliva', label: _vm.$t('form_pcr_saliva') },
              { value: 'pcr-rhino', label: _vm.$t('form_pcr_rhino') },
              { value: 'lctq-results', label: _vm.$t('form_quantitative') },
              { value: 'lcqtof-results', label: _vm.$t('form_qualitative') },
              {
                value: 'qualitative-lcqtof-results',
                label: _vm.$t('form_qualitative_lcqtof'),
              },
              { value: 'ecbu-results', label: _vm.$t('form_ecbu') },
              { value: 'group-results', label: _vm.$t('form_group') },
              { value: 'rai-results', label: _vm.$t('form_rai') },
              { value: 'mst-results', label: _vm.$t('form_mst') },
              { value: 'icpms-results', label: _vm.$t('form_icpms') }
            ]}}),(!_vm.hideCollectionDate)?_c('FormulateInput',{staticClass:"col-md-6 offset-md-3 col-12 py-3 pb-5",attrs:{"type":"date","label":_vm.$t('form_collection_date'),"validation-name":_vm.$t('form_collection_date'),"name":"record_collection_date","validation":"required","placeholder":_vm.$t('form_collection_date')}}):_vm._e(),_c('FormulateInput',{staticClass:"col-12 col-md-6 offset-md-3 pb-3",attrs:{"type":"file","name":"file","label":_vm.$t('form_file'),"validation":"required","validation-name":_vm.$t('form_file')}}),_c('FormulateInput',{staticClass:"col-12 col-md-8 offset-md-2 pb-5 pt-3 text-center submit-btn",attrs:{"type":"submit","label":_vm.$t('form_import')}})],1)],1)],1),_c('b-container',{attrs:{"fluid":"","hidden":_vm.hideTable}},[_c('b-row',[_c('vue-good-table',{staticClass:"col-12 pb-5",attrs:{"columns":_vm.columns,"rows":_vm.importData,"compactMode":"","search-options":_vm.tableSearch(),"pagination-options":_vm.tablePagination()}}),_c('div',{staticClass:"col-12 text-right pb-5"},[_c('button',{staticClass:"button",on:{"click":_vm.submitData}},[_vm._v(" "+_vm._s(_vm.$t("button_send"))+" ❯ ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }